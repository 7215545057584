window.addEventListener('DOMContentLoaded', function() {
    var inputEmailLogin = document.querySelector("#login-form-email");
    var seePasswordLogin = document.querySelector("#togglePassword");

    inputEmailLogin.addEventListener("blur", function(){
        var errorEmail = document.querySelector("#form-email-error")
        var email = inputEmailLogin.value;
        var regex = /^(?!$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if(!regex.test(email) && email.length > 0){
            errorEmail.style.display = "block";
            errorEmail.textContent = "Por favor, insira um endereço de e-mail válido. Exemplo: gopharma@interplayers.com.br";
        }else if(regex.test(email)){
            errorEmail.style.display = "none";
        }
    })

    seePasswordLogin.style.userDrag = 'none';
    seePasswordLogin.style.webkitUserDrag = 'none';
    seePasswordLogin.style.MozUserDrag = 'none';
});

var forgetPassword = document.querySelectorAll(".forgetPassword");

document.addEventListener('DOMContentLoaded', function() {
    forgetPassword.forEach(function(element) {
        element.style.pointerEvents = 'none';
    });
});

window.addEventListener('load', function() {
    forgetPassword.forEach(function(element) {
        element.style.pointerEvents = 'auto';
    });
});
